const getDomElements = () => {
	return {
		root: document.querySelector(
			'.researchModal'
		) as HTMLDivElement | null,
		toggleBtn: document.querySelector(
			'.researchModal_toggle-btn'
		) as HTMLButtonElement | null,
		modal: document.querySelector(
			'.researchModal_content'
		) as HTMLDivElement | null,
	};
};

const execute = (): void => {
	const { root, toggleBtn, modal } = getDomElements();

	const cachedButtonText = toggleBtn?.textContent ?? '';

	const handleModalButtonClick = () => {
		// Preserve active state of modal
		root?.classList.toggle('researchModal--active');
		// Show content
		modal?.classList.toggle('researchModal_content--show');

		// Add "close" to button text
		if (toggleBtn) {
			if (
				// Modal is open
				root?.classList.contains('researchModal--active')
			) {
				toggleBtn.textContent = cachedButtonText + ' (Close)';
			} else {
				toggleBtn.textContent = cachedButtonText;
			}
		}
	};

	toggleBtn?.addEventListener('click', handleModalButtonClick);
};

execute();
